<template>
  <v-card v-if="gcCommon_pageLoaded" class="position-relative" max-width="640" min-width="320" tile width="100%">
    <!--  이미지: 초청 이미지  -->
    <InviteImage />

    <!--  완료: 초청  -->
    <template v-if="gcInvite_submitted || gcInvite_inviteInfo.reg_state === 0">
      <InvitedAfterText />

      <!--  푸터  -->
      <v-card-actions class="px-4 pt-0 pb-6 flex-column text-indent-5">
        <!--  정보: 안내  -->
        <ContactText />
      </v-card-actions>
    </template>

    <!--  작성: 초청  -->
    <template v-else>
      <!--  폼  -->
      <v-card-text class="black--text pt-3 pb-0">
        <!--  구분: 의사 정보  -->
        <v-form id="s-form" ref="form" v-model="form" :disabled="gcInvite_submitting">

          <h1 class="text-h6 font-weight-bold mb-2 d-flex align-center">
            참석자 정보입력
            <InviteStateText/>
          </h1>

          <!--  인풋: 이름  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_name_enable"
            v-model="gcInvite_formData.invitee_name"
            :label="gcInvite_inviteInfo.invitee_name_label || '이름'"
            :rules="[required]"
            clearable
            dense
            outlined
            type="text"/>

          <!--  인풋: 소속기관  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_company_enable"
            v-model="gcInvite_formData.invitee_company"
            :label="gcInvite_inviteInfo.invitee_company_label || '소속기관'"
            :rules="[required]" clearable dense outlined type="text"/>

          <!--  인풋: 부서  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_part_enable"
            v-model="gcInvite_formData.invitee_part"
            :label="gcInvite_inviteInfo.invitee_part_label || '부서'"
            :rules="[required]" clearable dense outlined type="text"/>

          <!--  인풋: 연락처  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_phone_enable"
            v-model="gcInvite_formData.invitee_phone"
            :label="gcInvite_inviteInfo.invitee_phone_label || '연락처'"
            :rules="[required]" clearable dense outlined placeholder=" - 없이 입력해주세요" type="tel"/>

          <!--  인풋: 이메일  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_email_enable"
            v-model="gcInvite_formData.invitee_email"
            :label="gcInvite_inviteInfo.invitee_email_label || '이메일'"
            :rules="[required]" clearable dense outlined type="text"/>

          <!--  인풋: 숙박여부  -->
          <v-select
            v-if="gcInvite_inviteInfo.invitee_stay_enable"
            v-model="gcInvite_formData.invitee_stay"
            :items="stayList" :label="gcInvite_inviteInfo.invitee_stay_label || '숙박여부'"
            dense outlined/>

          <!--  인풋: 고객코드  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_code_enable"
            v-model="gcInvite_formData.invitee_code"
            :label="gcInvite_inviteInfo.invitee_code_label || '고객코드'"
            :rules="[required]" clearable dense outlined type="text"/>

          <!--  인풋: 거래처코드  -->
          <v-text-field
            v-if="gcInvite_inviteInfo.invitee_vendor_code_enable"
            v-model="gcInvite_formData.invitee_vendor_code"
            :label="gcInvite_inviteInfo.invitee_vendor_code_label || '거래처코드'"
            :rules="[required]" clearable dense outlined type="text"/>

          <!--  인풋: 커스텀 필드  -->
          <v-text-field
            v-for="(item, i) in gcInvite_inviteInfo.invitee_custom_field"
            :key="`invitee_custom_field-${i}`"
            v-model="gcInvite_formData.invitee_custom_field[i]"
            :label="item.label" :rules="[required]" clearable dense outlined type="text"/>

          <v-divider class="mt-3 mb-8"/>

          <h1 class="text-h6 font-weight-bold mb-2">담당자 정보입력</h1>

          <!--  인풋: 부서(대)  -->
          <v-select
            v-if="part1List.length > 1"
            v-model="gcInvite_formData.sales_part1"
            :items="part1List" dense item-text="name"
            :label="gcInvite_teamLabel[0]" outlined @change="onchangePart1"/>

          <!--  인풋: 부서(대)-직접입력  -->
          <v-text-field
            v-if="gcInvite_formData.sales_part1 === 0"
            v-model="gcInvite_formData.sales_part1_typed"
            :rules="[required]" clearable dense
            :label="`${ gcInvite_teamLabel[0] }(직접입력)`" outlined type="text"/>

          <!--  인풋: 부서(중)  -->
          <v-select
            v-if="gcInvite_teamInfo.second_level_enable"
            v-model="gcInvite_formData.sales_part2"
            :items="part2List" dense item-text="name"
            :label="gcInvite_teamLabel[1]" outlined @change="onchangePart2"/>

          <!--  인풋: 부서(중)-직접입력  -->
          <v-text-field
            v-if="gcInvite_formData.sales_part2 === 0"
            v-model="gcInvite_formData.sales_part2_typed"
            :rules="[required]" clearable dense
            :label="`${ gcInvite_teamLabel[1] }(직접입력)`" outlined type="text"/>

          <!--  인풋: 부서(소)  -->
          <v-select
            v-if="gcInvite_teamInfo.third_level_enable"
            v-model="gcInvite_formData.sales_part3"
            :items="part3List" dense item-text="name"
            :label="gcInvite_teamLabel[2]" outlined @change="onchangePart3"/>

          <!--  인풋: 부서(소)-직접입력  -->
          <v-text-field
            v-if="gcInvite_formData.sales_part3 === 0"
            v-model="gcInvite_formData.sales_part3_typed"
            :rules="[required]" clearable dense
            :label="`${ gcInvite_teamLabel[2] }(직접입력)`" outlined type="text"/>

          <!--  인풋: 사번  -->
          <v-text-field
            v-model="gcInvite_formData.sales_number"
            :rules="[required]" clearable dense
            label="사원번호" outlined type="text"/>

          <!--  인풋: 이름  -->
          <v-text-field
            v-model="gcInvite_formData.sales_name"
            :rules="[required]" clearable dense
            label="이름" outlined type="text"/>

          <!--  인풋: 연락처  -->
          <v-text-field
            v-model="gcInvite_formData.sales_phone"
            :rules="[required]" clearable dense label="휴대폰번호"
            outlined placeholder=" - 없이 입력해주세요" type="tel"/>

          <!--  개인정보 동의  -->
          <template v-if="gcInvite_inviteInfo.invitee_terms_enable">
            <v-divider class="mt-2"/>

            <v-checkbox
              v-model="gcInvite_formData.invitee_terms"
              :label="gcInvite_inviteInfo.invitee_terms_label"
              :rules="[checked]" class="text-indent-5 word-break-keep-all mt-6">
              <template v-slot:append>
                <v-btn
                  depressed outlined small
                  @click="gmCommon_alertHtmlOpen(gcInvite_inviteInfo.invitee_terms_html)">
                  더보기
                </v-btn>
              </template>
            </v-checkbox>
          </template>

          <v-divider class="mt-3 mb-9"/>

          <!--  구분: 영업 정보  -->
        </v-form>
      </v-card-text>

      <!--  푸터  -->
      <v-card-actions class="px-4 pt-0 pb-6 flex-column text-indent-5">
        <!--  버튼: 등록  -->
        <v-btn
          :loading="gcInvite_submitting" block class="mb-6 font-weight-bold"
          color="blue" dark depressed @click.stop="onClickInvite">
          <v-icon class="mr-2">mdi-send</v-icon>
          등록하기
        </v-btn>

        <!--  정보: 안내  -->
        <ContactText />

      </v-card-actions>
    </template>

  </v-card>
</template>

<script>
import env from '../env'
import ContactText from '@/components/ContactText.vue'
import InviteImage from '@/components/InviteImage.vue'
import InvitedAfterText from '@/components/InvitedAfterText.vue'
import InviteStateText from '@/components/InviteStateText.vue'

export default {
  name: "Invite",
  components: { InviteStateText, InvitedAfterText, InviteImage, ContactText },

  async mounted() {
    // 초기화
    this.gcInvite_submitting = false
    this.gcInvite_submitted = false

    // 정보가 없을 경우 불러오기
    if (!this.gcInvite_inviteInfo?.id) {
      const invite_id = this.$route.params.invite_id

      this.gcCommon_pageLoaded = false

      await this.API_getInviteInfo(invite_id)

      const event_id = this.gcInvite_inviteInfo?.event?.id || 0

      this.event_id = event_id
      event_id && await this.API_getInviteTeam(event_id)

      this.gcCommon_pageLoaded = true
    }

    this.$store.commit('gmInvite_initForm')
  },

  data: () => ({
    event_id: 0,

    form: false,

    checkbox: false,
  }),

  computed: {
    required() {
      return value => !!value || '내용을 입력해주세요.'
    },

    checked() {
      return value => !!value || '동의가 필요합니다.'
    },

    stayList() {
      return [
        { value: 'O', text: '숙박' },
        { value: 'X', text: '비숙박' },
      ]
    },

    part1List() {
      const list = this.gcInvite_team1.map(item => ({ ...item, value: item.id }))

      list.unshift({ name: '해당없음', value: -1 })

      this.gcInvite_teamInfo.custom_input_enable &&
      list.push({ name: '직접입력', value: 0 })

      return list
    },

    part2List() {
      const list = [{ name: '해당없음', value: -1 }]
      const parent_id = this.gcInvite_formData.sales_part1
      this.gcInvite_team2.forEach(item => {
        (item.parent_id === parent_id) && list.push({ ...item, value: item.id })
      })

      this.gcInvite_teamInfo.custom_input_enable && list.push({ name: '직접입력', value: 0 })

      return list
    },

    part3List() {
      const list = [{ name: '해당없음', value: -1 }]
      const parent_id = this.gcInvite_formData.sales_part2
      this.gcInvite_team3.forEach(item => {
        (item.parent_id === parent_id) && list.push({ ...item, value: item.id })
      })

      this.gcInvite_teamInfo.custom_input_enable && list.push({ name: '직접입력', value: 0 })

      return list
    },
  },

  methods: {
    onchangePart1() {
      this.gcInvite_formData.sales_part1_typed = ''
      this.gcInvite_formData.sales_part2 = -1
      this.onchangePart2()
    },

    onchangePart2() {
      this.gcInvite_formData.sales_part2_typed = ''
      this.gcInvite_formData.sales_part3 = -1
      this.onchangePart3()
    },

    onchangePart3() {
      this.gcInvite_formData.sales_part3_typed = ''
    },

    // 클릭: 초청하기
    async onClickInvite() {
      const valid = this.$refs.form?.validate()

      // 유효성 검사
      if (valid) {
        const id = this.$store.state.gsInvite_inviteInfo.id
        const form = this.gcInvite_formData

        this.gcInvite_submitting = true

        localStorage.setItem(`${id}_sales_part1`, `${form.sales_part1}`)
        localStorage.setItem(`${id}_sales_part1_typed`, form.sales_part1_typed)
        localStorage.setItem(`${id}_sales_part2`, `${form.sales_part2}`)
        localStorage.setItem(`${id}_sales_part2_typed`, form.sales_part2_typed)
        localStorage.setItem(`${id}_sales_part3`, `${form.sales_part3}`)
        localStorage.setItem(`${id}_sales_part3_typed`, form.sales_part3_typed)
        localStorage.setItem(`sales_number`, form.sales_number)
        localStorage.setItem(`sales_name`, form.sales_name)
        localStorage.setItem(`sales_phone`, form.sales_phone)

        const params = {
          event_id: this.gcInvite_inviteInfo?.event?.event_id ||
            this.gcInvite_inviteInfo.event?.id || 0,
          ...form,
          invitee_terms: Number(form.invitee_terms),
          invitee_custom_field: [],
        }

        delete params.invitee_custom_field
        params.invitee_user_custom_field = []

        this.gcInvite_inviteInfo.invitee_custom_field.forEach((item, i) => {
          params.invitee_user_custom_field.push({
            invitee_custom_field_id: item.id,
            value: form.invitee_custom_field[i]
          })
        })

        const result = await this.API_postSubmit(params)

        if (result === true || env.DEV_MODE) {
          this.gcInvite_submitted = true
        }
        else {
          result && this.gmCommon_alertOpen({
            text: `등록에 실패하였습니다.\nError code: ${ result }`
          })
        }

        this.gcInvite_submitting = false
      }
      else {
        // 부적격: 타겟 찾은 후 포커싱 (비동기 처리 해야 타겟 찾을 수 있음)
        setTimeout(() => document.querySelector('.v-input.error--text')?.scrollIntoView())
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.s-gap-1
  gap: 1rem
a
  margin-left: .5rem
  text-decoration: none
  font-weight: bold
  color: map-get($blue, 'base') !important
</style>
